export const dashboardsMap: Record<string, string> = {
  ["DEALERNET-BDC - Desempenho Pós-Vendas"]:
    "https://proxy.metoncloud.com.br/mercosul-bi/36fd9aed-d76a-4961-b527-7c01b26a4caf/",
  ["0-1-TABELAS - Cadastro de Metas e Comissões Pós-Vendas"]:
    "https://proxy.metoncloud.com.br/mercosul-bi/83de96c3-1727-4409-b2cc-482ac9ce4662/",
  ["0-2-AUTOMAÇÕES-OLD - Automação Webmotors - FIPE"]:
    "https://proxy.metoncloud.com.br/mercosul-bi/6bc5cd79-8d23-4f40-ab44-4dc30fc568c1/",
  ["0-1-TABELAS - Editor de Cotações de Veiculos Usados"]:
    "https://proxy.metoncloud.com.br/mercosul-bi/f6a595ce-4a31-43f5-b7b9-1c8bb1ceb450/",
  ["DEALERNET-BDC - Desempenho Diário do Agente"]:
    "https://proxy.metoncloud.com.br/mercosul-bi/fb315b90-aaba-4812-9792-f7765b9873b5/",
  ["TESTES - AUTH"]:
    "https://proxy.metoncloud.com.br/mercosul-bi/2927219b-10c7-4eef-96db-0ecc902bbaf0/",
  ["TOYOPAR - One Page Report"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro10/3ade3f70-93c4-4d5a-94a1-6d88753134be/",
  ["RAVPRO - ADMIN - SMS"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro9/4745a27d-aa9e-4c77-bc08-caf47e8d6b4c/",
  ["RAVPRO - ADMIN - PAINEL DE CONTROLE"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro7/e1148d47-6490-4a10-8881-bb301836d8cf/",
  ["RAVPRO - ADMIN - PREMIAÇÕES - ADMIN"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro4/a08d3c6f-b260-4058-adaa-3722c6831ce1/",
  ["RAVPRO - ADMIN - SANITIZANTES - DAF"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro8/6d2cc93e-6f16-4002-8e75-364ee709ed76/",
  ["RAVPRO - PREMIAÇÕES - Premiações Manuais"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro2/88b34eb3-b918-4ac1-86c2-123c7a663bed/",
  ["RAVPRO - PREMIAÇÕES - Premiações Integradas"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro1/36fd9aed-d76a-4961-b527-7c01b26a4caf/",
  ["RAVPRO - ACOMPANHAMENTO DE PEDIDOS"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro3/880aec16-4417-4e50-b5ce-8160961436df/",
  ["RAVPRO - OPR - MANUAIS"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro6/27e6b495-82e6-4da9-91ac-24a29a5a30d8/",
  ["RAVPRO - OPR - INTEGRADAS"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro5/4b57d159-080a-4f89-abf0-6e8e6e60ee64/",
  ["RAVPRO - PREMIAÇÕES - Premiações Integradas Gerentes"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro13/a50eb6c3-6715-43e0-86ce-969f7ff801c2/",
  ["RAVPRO - PREMIAÇÕES - Premiações Manuais Gerentes"]:
    "https://proxy.dev-ravpro.scalar.ws/ravpro14/30d9221c-262d-4c7d-80a9-8c47a50434bf/",
};
